body {
  margin: 0;
  height: 100%;
}

hr {
  background-color: #222;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}